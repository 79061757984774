(function () {
  const popups = document.querySelectorAll('.popup');
  if (popups) {
    popups.forEach(popup => {
      openPopup(popup)

      const closeButton = popup.querySelectorAll('.popup__close, .popup__close-link');
      closeButton.forEach(close => {
        close.addEventListener('click', function (e) {
          e.preventDefault()
          popup.classList.remove('opened');
          bodyScrollLock.clearAllBodyScrollLocks();
        })
      })
    })
  }

  function openPopup(el) {
    el.classList.add('opened');
    bodyScrollLock.disableBodyScroll(el);
  }
})()