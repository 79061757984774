(function () {

    const $header = document.querySelector('.page-header');
    const $menu = document.querySelector('.main-nav');
    const $hamburger = document.querySelector('.burger');
    const $langSwitcher = $header.querySelector('.lang-switcher');
    const $headerRight = $header.querySelector('.page-header__right');
    let submenuItems = document.querySelectorAll('.menu-item-has-children');
    let submenuItems1 = document.querySelectorAll('.main-nav ul > .menu-item-has-children');

    let isMenuOpened = false;

    $hamburger.addEventListener('click', function () {
        this.classList.toggle('opened')
        $menu.classList.toggle('opened');
        $header.classList.toggle('menu-opened');
        isMenuOpened = !isMenuOpened;

        if (isMenuOpened) {
            bodyScrollLock.disableBodyScroll($menu);
        } else {
            bodyScrollLock.clearAllBodyScrollLocks();
        }
        for (let elem of submenuItems) {
            elem.classList.remove('children-menu-opened');
        }
    });

    if (window.innerWidth < 768) {
        if (!document.querySelector('.main-nav > .lang-switcher')) {
            $menu.prepend($langSwitcher);
        }
    }

    window.addEventListener('resize', debounce(function () {
        if (isMenuOpened && window.innerWidth >= 768) {
            bodyScrollLock.clearAllBodyScrollLocks();
            $header.classList.remove('second-menu-opened');
        }
        if (window.innerWidth >= 768) {
            if (!document.querySelector('.page-header__right > .lang-switcher')) {
                $headerRight.append($langSwitcher);
            }
        } else {
            if (!document.querySelector('.main-nav > .lang-switcher')) {
                $menu.prepend($langSwitcher);
            }
        }
    }))

    for (let item of submenuItems1) {
        let title = item.children[0].textContent;
        let linkHref = item.children[0].getAttribute('href')
        let node = document.createElement('li');
        let link = document.createElement('a');
        link.textContent = title;
        link.setAttribute('href', linkHref)
        node.classList.add('sub-menu__title');
        item.querySelector('.menu').prepend(node)
        node.prepend(link)
    }

    for (let item of submenuItems) {
        item.addEventListener('click', function (e) {
            if (window.innerWidth < 768) {
                if (!this.classList.contains('children-menu-opened')) {
                    e.stopPropagation();
                    e.preventDefault();
                    this.classList.add('children-menu-opened');
                    $menu.classList.add('submenu-opened');
                }
            }
        })
    }
    for (let item of submenuItems) {
        item.addEventListener('mouseenter', function (e) {
            if (window.innerWidth >= 768) {
                if (!this.classList.contains('children-menu-opened')) {
                    if(this.parentElement.classList.contains('menu')) {
                        let parentMenu = this.parentElement;
                        let childrenMenu = this.querySelector('.menu');
                        let freeSpace = window.outerWidth - parentMenu.getBoundingClientRect().right;
                        if (freeSpace < childrenMenu.getBoundingClientRect().width) {
                            this.classList.add('left-sided-submenu');
                            childrenMenu.classList.add('left-sided')
                        }
                    }
                    this.classList.add('children-menu-opened');
                    $menu.classList.add('submenu-opened');
                }
            }
        })
    }

    for (let item of submenuItems) {
        item.addEventListener('mouseleave', function (e) {
            if (window.innerWidth >= 768) {
                if (this.classList.contains('children-menu-opened')) {
                    this.classList.remove('children-menu-opened');
                    this.parentNode.classList.remove('left-sided-submenu');
                    this.querySelector('.menu').classList.remove('left-sided')
                    $menu.classList.remove('submenu-opened');
                }
            }
        })
    }

    document.addEventListener('click', function (e) {
        let target = e.target
        if (target.classList.contains('sub-menu__title')) {
            target.closest('.menu-item-has-children').classList.remove('children-menu-opened');
        }

        if (!target.closest('.menu-item-has-children')) {
            for (let item of submenuItems) {
                item.classList.remove('children-menu-opened');
            }
        }
    })


    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
})();
