(function() {
  const $header = document.querySelector('.page-header');
  if ( $header ) {
    let topStr = window.getComputedStyle($header,null).getPropertyValue("top");
    let top = topStr.substr(0, topStr.length-2)
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const sticky = document.body.scrollTop + $header.offsetTop;

    function stickyHeader() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // downscroll code
        $header.classList.remove('scrolled--visible');
        $header.style.top = ''
      } else {
        // upscroll code
        $header.classList.add('scrolled--visible');
        $header.style.top = ''
      }

      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

      $header.classList.toggle('scrolled', window.pageYOffset > sticky);

      if (window.pageYOffset <= sticky) {
        $header.classList.remove('scrolled--visible','scrolled');
        $header.style.top = (top - window.pageYOffset) + 'px'
      }
    }

    window.addEventListener('scroll', stickyHeader)
    window.addEventListener('load', stickyHeader)
  }

})();
