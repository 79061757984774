document.addEventListener("DOMContentLoaded", function() {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./slider');
  require('./header');
  require('./menu');
  require('./equalizeHeight');
  require('./filters');
  require('./video');
  require('./popup')
  equalizeHeight('.board-card__title')
});

// remove preloader
window.addEventListener('load', function () {
  // $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  equalizeHeight('.board-card__title')

  const container = document.querySelector('.tab-container')
  if (container) {
    tabs(container);
  }

});


window.addEventListener('resize', function () {
  equalizeHeight('.board-card__title')
})

function equalizeHeight (className) {
  const collection = document.querySelectorAll(className);
  let titleMaxHeight = 0
  collection.forEach(el => {
        titleMaxHeight = Math.max(titleMaxHeight, el.offsetHeight);
      }
  )
  collection.forEach(el => el.style.minHeight = titleMaxHeight + 'px')
}


